export const NEW_ALBUMS = [
  {
    headline: {
      desktop: 'Swimmer - NEW ALBUM',
      mobile: ['New Album OUT NOW','Swimmer'],
    },
    title: "Swimmer",
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-swimmer.png",
    releasedAt: "Nov 2024",
    songs: [
      "Frohsinn '82",
      "Kayoesque",
      "Gown",
      "Final Girl",
      "Space Again",
      "Blinds",
      "Cling Wrap",
      "Pallas's Cat",
      "Amanda K",
      "Infinity Pool"
    ],
    isTowerRecordEnable:true,
    isdefaultEnable:true,
    ispreorderEnable:false,
    isecsiteEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa",
      "Mastered by kentaro kimura"
    ],
      links: {
        appleMusic: "https://music.apple.com/jp/album/swimmer/1779684946",
        spotify: "https://open.spotify.com/intl-ja/album/6w0FQyxaLKJpSEhvj05YTF?si=oGV1j_v2TZyVRJdsTIQOLg",
        bandcamp: "https://johnnivan.bandcamp.com/track/frohsinn-82",
        towerRecords: "https://tower.jp/item/6447163"
      }
  }
];

export const DISCOGRAPHY = [
  {
    title: "Swimmer",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-swimmer.png",
    releasedAt: "Nov 2024",
    songs: [
      "Frohsinn '82",
      "Kayoesque",
      "Gown",
      "Final Girl",
      "Space Again",
      "Blinds",
      "Cling Wrap",
      "Pallas's Cat",
      "Amanda K",
      "Infinity Pool"
    ],
    isTowerRecordEnable:true,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa",
      "Mastered by kentaro kimura"
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/swimmer/1779684946",
      spotify: "https://open.spotify.com/intl-ja/album/6w0FQyxaLKJpSEhvj05YTF?si=oGV1j_v2TZyVRJdsTIQOLg",
      bandcamp: "https://johnnivan.bandcamp.com/track/frohsinn-82",
      towerRecords: "https://tower.jp/item/6447163"
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/CSJdib8lU8c?si=6qwDpsVF9NhCjHt0"
    ]
  },
  {
    title: "Frohsinn '82",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-frohsinn82.png",
    releasedAt: "Nov 2024",
    songs: [
      "Frohsinn '82"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa",
      "Mastered by kentaro kimura"
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/frohsinn-82-single/1773197325",
      spotify: "https://open.spotify.com/intl-ja/album/4tfFB4JOPFnOw15CgXx7SI?si=BLq-lVJdSVSZqDiDgQ8yfQ",
      bandcamp: "https://johnnivan.bandcamp.com/track/frohsinn-82"
    }
  },
  {
    title: "White Bicycle, Lightweight",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-wblw.png",
    releasedAt: "Mar 2024",
    songs: [
      "White Bicycle",
      "Lightweight"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa", 
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/white-bicycle-lightweight-single/1735905897",
      spotify: "https://open.spotify.com/intl-ja/album/1INXO24r1UTvSlMxiA0QZb?si=Q0SaxDg0Qo22NKy7ja_b1g",
      bandcamp: "https://johnnivan.bandcamp.com/album/white-bicycle-lightweight"
    }
  },
  {
    title: "Final Girl",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-finalgirl.png",
    releasedAt: "Jan 2024",
    songs: [
      "Final Girl"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa", 
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/final-girl-single/1728378546",
      spotify: "https://open.spotify.com/intl-ja/album/1Biax0BCWarMhtWhdsgAfm?go=1&nd=1&dlsi=2d85bf623dd641a3",
      bandcamp: "https://johnnivan.bandcamp.com/track/final-girl"
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/V8KVD2T-zA4?si=fRGo1ZJt1g2Pv9fN"
    ],
  },
  {
    title: "Kayoesque",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-kayoesque.png",
    releasedAt: "Dec 2023",
    songs: [
      "Kayoesque"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa", 
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/kayoesque-single/1715802820",
      spotify: "https://open.spotify.com/intl-ja/album/4S6T3yQD7L4j88AJgkneKX?si=pS6o5UpER86Gd2Tgl-K2Ig",
      bandcamp: "https://johnnivan.bandcamp.com/track/kayoesque"
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/_HcUSwTBoS8?si=0KLflE1c0Gm1bhDN"
    ],
  },
  {
    title: "Give In!",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-GiveIn!.png",
    releasedAt: "Nov 2022",
    songs: [
      "Discomfort",
      "Lime Country",
      "Spare Pieces",
      "Table for Two",
      "Chipped Tooth",
      "No One is Going to Save You",
      "Helpless",
      "I’m Only Visiting",
      "Do You See Me?",
      "Forever",
      "Otherwise"
    ],
    isTowerRecordEnable:true,
    isdefaultEnable:true,
    ispreorderEnable:false,
    isecsiteEnable:true,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa", 
    ],
    links: {
      preorder:"https://orcd.co/givein",
      appleMusic: "https://music.apple.com/jp/album/give-in/1649561733",
      spotify:"https://open.spotify.com/album/0FM6rlBK5y9tkGpvRzqKOZ?si=Qdf5Z7mNSEuQtYfvJqbI2A",
      ecsite: "https://johnnivan-ec.myshopify.com/products/give-in",
      towerRecords: "https://tower.jp/item/5541719/Give-In!",
      bandcamp: "https://johnnivan.bandcamp.com/album/give-in"
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/5AO08mTgND8",
      "https://www.youtube.com/embed/w1w302WF4mQ",
      "https://www.youtube.com/embed/2PRrEc2id6c",
      "https://www.youtube.com/embed/8KWZIR_KrYU",
      "https://www.youtube.com/embed/sa7cBnYmB5g",
    ],
  },
  {
    title: "I'm Only Visiting",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-imonlyvisiting.jpg",
    releasedAt: "Nov 2022",
    songs: [
      "I'm Only Visiting"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa", 
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/im-only-visiting/1649561733?i=1649561955",
      spotify:
        "https://open.spotify.com/album/5bc56rCQTmmxO5nzofplNJ?si=5pPv8NuoTI-VbSJklAmULQ",
      bandcamp: "https://johnnivan.bandcamp.com/track/im-only-visiting",
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/8KWZIR_KrYU",
    ],
  },
  {
    title: "Lime Country",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-limecountry.png",
    releasedAt: "Oct 2022",
    songs: [
      "Lime Country"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa", 
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/lime-country-single/1646318709",
      spotify:
        "https://open.spotify.com/album/0yYgBALOxNmQn6xhSUlNds?si=m6An_O3DRKWAaHzrAfQNGA",
      bandcamp: "https://johnnivan.bandcamp.com/track/lime-country",
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/2PRrEc2id6c",
    ],
  },
  {
    title: "Spare Pieces",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-sparePieces.png?hl=ja",
    releasedAt: "Sep 2022",
    songs: [
      "Spare Pieces"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa", 
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/spare-pieces-single/1642861948?l=en",
      spotify:
        "https://open.spotify.com/album/20lyitvoEN20dVDIwiLIfa?si=Jj89E8-vQrulqqgyZhKOwg",
      bandcamp: "https://johnnivan.bandcamp.com/track/spare-pieces",
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/w1w302WF4mQ",
    ],
  },
  {
    title: "No One is Going to Save You",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-noOneIsGoingToSaveYou.png?hl=ja",
    releasedAt: "Aug 2022",
    songs: [
      "No One is Going to Save You"
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa",
    ],
    links: {
      appleMusic: "https://music.apple.com/jp/album/no-one-is-going-to-save-you-single/1639259954?l=en",
      spotify:
        "https://open.spotify.com/album/2dTDvBawUenOGXE7mGDmIe?si=_r8CRcZYQIek3q8feaDXJw",
      bandcamp: "https://johnnivan.bandcamp.com/track/no-one-is-going-to-save-you",
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/5AO08mTgND8",
    ],
  },
  {
    title: "Students",
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-students.jpeg?hl=ja",
    releasedAt: "Jun 2020",
    songs: [
      "Just Be Human",
      "Danced Once",
      "Service",
      "Calm Down",
      "Megs",
      "Boom Boom",
      "Bushwick",
      "Duty Free",
      "All You Ever Do",
      "Punch Sound Effects",
    ],
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa",
    ],
    isTowerRecordEnable: true,
    isdefaultEnable:true,
    ispreorderEnable:false,
    index: 6,
    links: {
      appleMusic: "https://music.apple.com/jp/album/students/1501764154",
      spotify:
        "https://open.spotify.com/album/5tp45o7OsQmv10wSe1aOw1?si=XlbNTZYURZqXduRQRnnYNw",
      towerRecords: "https://tower.jp/item/5025012/Students",
      bandcamp: "https://johnnivan.bandcamp.com/album/students",
    },
    youtubeLinks: [
      "https://www.youtube.com/embed/wBlNvSkzKJ0",
      "https://www.youtube.com/embed/eykU2qi76l4",
    ],
  },
  {
    title: "Danced Once",
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-dancedOnce.jpeg?hl=ja",
    releasedAt: "Apr 2020",
    songs: ["Danced Once"],
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa",
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    index: 5,
    links: {
      appleMusic: "https://music.apple.com/jp/album/students/1501764154",
      spotify: "https://open.spotify.com/album/3vEbJqP8KB8PLeKWmfsLvC",
      bandcamp: "https://johnnivan.bandcamp.com/album/students",
    },
    youtubeLinks: ["https://www.youtube.com/embed/eykU2qi76l4"],
  },
  {
    title: "Pilot",
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-pilot.jpeg?hl=ja",
    releasedAt: "Apr 2019",
    songs: [
      "Downer",
      "Nobody's Awake in This House",
      "Headband #2",
      "Late",
      "Green Screen",
      "My Man",
    ],
    attributes: [
      "Written and performed by Johnnivan",
      "Produced by takeshi iwamoto",
      "Engineered by daisuke takizawa",
    ],
    isTowerRecordEnable: true,
    isdefaultEnable:true,
    ispreorderEnable:false,
    index: 4,
    links: {
      appleMusic: "https://music.apple.com/jp/album/pilot-ep/1459999384",
      spotify: "https://open.spotify.com/album/32iLZQqsFCno54x1Ugbnil",
      towerRecords: "https://tower.jp/item/4903840/Pilot",
      bandcamp: "https://johnnivan.bandcamp.com/album/pilot",
    },
    youtubeLinks: ["https://www.youtube.com/embed/P98HA9tisXA"],
  },
  {
    title: "Community",
    titleFontSize: {
      mobile: 18,
      desktop: 32,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-community.jpeg?hl=ja",
    releasedAt: "Jan 2019",
    songs: ["Community"],
    attributes: [
      "All music written, performed, produced and engineered by Johnnivan",
    ],
    isTowerRecordEnable: false,
    isdefaultEnable:true,
    ispreorderEnable:false,
    index: 3,
    links: {
      appleMusic:
        "https://music.apple.com/jp/album/community-single/1448443028",
      spotify: "https://open.spotify.com/album/48Tfp0v4c0IQwm89AaKKjY",
      bandcamp: "https://johnnivan.bandcamp.com/track/community-2",
    },
  },
  {
    title: "Nobody's Awake In This House",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-nobody.jpeg?hl=ja",
    releasedAt: "Sep 2018",
    songs: ["Nobody's Awake in This House", "Y.G.M.B"],
    attributes: [
      "All music written, performed, produced and engineered by Johnnivan",
    ],
    isTowerRecordEnable: true,
    isdefaultEnable:true,
    ispreorderEnable:false,
    index: 2,
    links: {
      appleMusic:
        "https://music.apple.com/jp/album/nobodys-awake-in-this-house-single/1435730793",
      spotify: "https://open.spotify.com/album/7iQXYyz3Ua5RA4SJfcekV2",
      towerRecords: `https://tower.jp/item/4841223/Nobody's-Awake-In-This-House`,
      bandcamp:
        "https://johnnivan.bandcamp.com/album/nobodys-awake-in-this-house",
    },
    youtubeLinks: ["https://www.youtube.com/embed/P98HA9tisXA"],
  },
  {
    title: "Tomorrow Night / I Think I Know You",
    titleFontSize: {
      mobile: 16,
      desktop: 28,
    },
    cover:
      "https://storage.googleapis.com/johnnivan-static-assets/artworks/artwork-tomorrow.jpeg?hl=ja",
    releasedAt: "Apr 2018",
    songs: ["Tomorrow Night", "I Think I Know You"],
    attributes: [
      "All music written, performed, produced and engineered by Johnnivan",
    ],
    isTowerRecordEnable: true,
    isdefaultEnable:true,
    ispreorderEnable:false,
    index: 1,
    links: {
      appleMusic:
        "https://music.apple.com/jp/album/tomorrow-night-i-think-i-know-you-single/1387469934",
      spotify: "https://open.spotify.com/album/6CFGoBFBtHMlJqbVwci1SN",
      towerRecords: "https://tower.jp/item/4841214/Johnnivan",
      bandcamp:
        "https://johnnivan.bandcamp.com/album/tomorrow-night-i-think-i-know-you",
    },
  },
];
