import React, { useEffect, Fragment } from "react";
import { Link as SLink } from "react-scroll";
import cx from "classnames";
import { loadCSS } from "fg-loadcss";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
// import Drawer from '@material-ui/core/Drawer';
import Drawer from "@material-ui/core/SwipeableDrawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import AppleIcon from "@material-ui/icons/Apple";
import Icon from "@material-ui/core/Icon";
import {
  APPLE_MUSIC_LINK,
  SPOTIFY_LINK,
  BANDCAMP_LINK,
  INSTAGRAM_LINK,
  TWITTER_LINK,
  YOUTUBE_PAGE,
} from "../constants";

const useStyles = makeStyles((theme) => ({
  headerDrawer: {
    background: "transparent",
    opacity: 0.9,
    // flexShrink: 0,
    "& > .fab": {
      width: "48px",
      height: "48x",
      // margin: theme.spacing(2),
      ":before, *:after": {
        "-webkit-box-sizing": "content-box",
        boxSizing: "content-box",
      },
    },
  },
  fullList: {
    background: "#000",
    color: "#fefefe",
  },
  headerMargin: {
    width: "100%",
    height: 56,
    backgroundColor: "#111",
    marginBottom: theme.spacing(4),
  },
  headerMenu: {
    boxSizing: "border-box",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
    color: "#fefefe",
    "&:hover": {
      backgroundColor: "#222",
    },
  },
  divider: {
    background: "#333",
    height: "0.1px",
  },
  iconListWrapper: {
    borderTop: "solid 0.1px #333",
    borderBottom: "solid 0.1px #333",
  },
  iconList: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
  },
  iconButton: {
    width: "56px",
    height: "56px",
    color: "#fefefe",
    padding: 0,
  },
  iconButtonOnFA: {},
  icon: {
    width: "36px",
    height: "36px",
    opacity: 1,
  },
  languageSelect: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  languageSeparator: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  languageOption: {
    fontFamily: "Helvetica",
    fontSize: "1.1rem",
  },
  appleMusicIcon: {
    display: "inline-block",
    overflow: "hidden",
    background:
      "url(https://tools.applemusic.com/embed/v1/app-icon.svg?hex=FFFFFF) no-repeat",
    width: "40x",
    height: "40px",
  },
  customIcon: {
    color: "#ff0000",
    // background: '#ff0000',
  },
  // about appbar
  appbar: {
    boxSizing: "border-box",
    boxShadow: "none",
    margin: 0,
    backgroundColor: "transparent",
  },
  headerMenus: {
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 1300,
    width: "56px",
    height: "56px",
    background: "#CCCCCC 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029", // とりあえず直で使用する
  },
  menuIconButton: {
    width: "56px",
    height: "56px",
  },
  // ヘッダーのボタンのアイコン
  headerMenusIcon: {
    width: "24px",
    height: "24px",
    opacity: 1,
  },
}));

const HeaderDrawer = ({ toggleDrawer, setState, state }) => {
  const c = useStyles();

  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  const FAIconStyle = {
    // marginTop: '6px',
    paddingTop: "12px",
    width: 48,
    height: 48,
  };

  const HeaderItem = ({ side = "top", to, offset, duration = 1500, text }) => (
    <SLink
      activeClass="active"
      to={to}
      spy={true}
      smooth="easeInOutQuint"
      offset={offset}
      duration={duration}
    >
      <Typography onClick={toggleDrawer(side, false)} className={c.headerMenu}>
        {text}
      </Typography>
    </SLink>
  );

  const fullList = (side) => (
    <div className={c.fullList} role="presentation">
      <div className={c.headerMargin} />
      <HeaderItem
        offset={0}
        to="newRelease"
        text="Swimmer - NEW ALBUM"
      />
      <HeaderItem offset={0} to="lives" text="LIVE" />
      <HeaderItem offset={0} to="discography" text="DISCOGRAPHY" />
      {/* SHOP有効化時にuncomment */}
      <Link
        target="_blank"
        href={process.env.REACT_APP_SHOP_URL}
        style={{ textDecoration: "none" }}
      >
        <Typography
          onClick={toggleDrawer(side, false)}
          className={c.headerMenu}
        >
          SHOP
        </Typography>
      </Link>
      <HeaderItem offset={0} to="photos" text="PHOTO" />
      <HeaderItem offset={100} to="footer" text="CONTACT" />
      <div className={c.iconListWrapper}>
        <div className={c.iconList}>
          <Link target="_blank" href={APPLE_MUSIC_LINK}>
            <IconButton className={c.iconButton} aria-label="HeaderMenu">
              <AppleIcon className={c.icon} />
            </IconButton>
          </Link>
          <Link target="_blank" href={SPOTIFY_LINK}>
            <IconButton
              className={cx(c.iconButton, c.iconButtonOnFA)}
              aria-label="HeaderMenu"
            >
              <Icon className="fab fa-spotify" style={FAIconStyle} />
            </IconButton>
          </Link>
          <Link target="_blank" href={BANDCAMP_LINK}>
            <IconButton
              className={cx(c.iconButton, c.iconButtonOnFA)}
              aria-label="HeaderMenu"
            >
              <Icon className="fab fa-bandcamp" style={FAIconStyle} />
            </IconButton>
          </Link>
          <Link target="_blank" href={TWITTER_LINK}>
            <IconButton className={c.iconButton} aria-label="HeaderMenu">
              <TwitterIcon className={c.icon} />
            </IconButton>
          </Link>
          <Link target="_blank" href={INSTAGRAM_LINK}>
            <IconButton className={c.iconButton} aria-label="HeaderMenu">
              <InstagramIcon className={c.icon} />
            </IconButton>
          </Link>
          <Link target="_blank" href={YOUTUBE_PAGE}>
            <IconButton className={c.iconButton} aria-label="HeaderMenu">
              <YouTubeIcon className={c.icon} />
            </IconButton>
          </Link>
        </div>
      </div>
    </div>
  );
  return (
    <Fragment>
      <Drawer
        className={c.headerDrawer}
        anchor="top"
        open={state.top}
        onOpen={() => {}}
        onClose={toggleDrawer("top", false)}
        transitionDuration={{ enter: 500, exit: 250 }}
      >
        {fullList("top")}
      </Drawer>
    </Fragment>
  );
};

export default HeaderDrawer;
